<template>
    <div class="c-dark-footer">
        <div class="c-dark-footer__inner b-nal-layout__inner">
            <div
                class="c-dark-footer__up"
                @click="toTop"
            >
                <i class="c-dark-footer__arrow fal fa-angle-up u-hover--grow">
                </i>
            </div>
            <div class="c-dark-footer__main">
                <div class="c-dark-footer__image">
                    <router-link
                        to="/home"
                        exact
                    >
                        <div
                            class="c-dark-footer__logo"
                            style="background-image: url('/images/logo-white.png')"
                        >
                        </div>
                    </router-link>
                </div>
                <div class="c-dark-footer__links">
                    <p class="c-dark-footer__header">
                        Quick Links
                    </p>
                    <div class="c-dark-footer__content">
                        <router-link
                            :to="termsLink('privacy-policy')"
                            class="c-dark-footer__link u-hover--line"
                        >
                            {{ ( trans('common.privacy-policy', {}, 'en')) }}
                        </router-link>
                        <router-link
                            :to="termsLink('cookie-policy')"
                            class="c-dark-footer__link u-hover--line"
                        >
                            {{ ( trans('common.cookie-policy', {}, 'en')) }}
                        </router-link>

                        <router-link
                            :to="termsLink('user-agreement')"
                            class="c-dark-footer__link u-hover--line"
                        >
                            {{ ( trans('common.agreement', {}, 'en')) }}
                        </router-link>

                        <router-link
                            class="c-dark-footer__link u-hover--line c-dark-footer__partner"
                            :to="{ name: 'partners' }"
                        >
                            {{ ( trans('home-nal.footer.partners', {}, 'en')) }}
                        </router-link>

                    </div>
                </div>
                <div class="c-dark-footer__address">
                    <p class="c-dark-footer__header">
                        Not Actively Looking
                    </p>
                    <div class="c-dark-footer__content">
                        <p class="c-dark-footer__company">
                            {{ trans('common.company-info', {}, 'en') }}
                        </p>
                        <p>{{ trans('common.address', {}, 'en') }}</p>
                    </div>

                </div>
            </div>
            <div class="c-dark-footer__footer">
                <div class="c-dark-footer__copyright">
                    &copy;
                    {{ year }}
                    &nbsp;
                    Not Actively Looking
                </div>
                <div class="c-dark-footer__language">
                    <a
                        class="u-hover--line"
                        :href="localeLink('en')"
                    >
                        EN
                    </a>
                    |
                    <a
                        class="u-hover--line"
                        :href="localeLink('de')"
                    >
                        DE
                    </a>
                    |
                    <a
                        class="u-hover--line"
                        :href="localeLink('pt-BR')"
                    >
                        PT-BR
                    </a>
                </div>
                <div class="c-dark-footer__icons">
                    <a
                        class="fab fa-linkedin c-dark-footer__icon u-hover--grow"
                        href="https://www.linkedin.com/company/not-actively-looking/"
                        target="_blank"
                    >
                    </a>
                    <a
                        class="fab fa-square-x-twitter c-dark-footer__icon u-hover--grow"
                        href="https://twitter.com/NALexeccareers"
                        target="_blank"
                    >
                    </a>
                    <a
                        class="fab fa-facebook-square c-dark-footer__icon u-hover--grow"
                        href="https://www.facebook.com/NotActivelyLooking/"
                        target="_blank"
                    >
                    </a>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NalFooter',
    computed: {
        year() {
            return (new Date).getFullYear();
        },
    },
    methods: {
        toTop() {
            window.scroll(0, 0);
        },
        localeLink(lang) {
            return `/locale/${lang}?hash=${this.$route.path}`;
        },
        termsLink(terms) {
            return { name: 'terms', params: { tab: terms } };
        },
    },
};
</script>

<style lang="scss" scoped>

@import "@sass/home-nal/variables";
@import "@sass/home-nal/utilities";

$padding: 30px;

.c-dark-footer {
    background-color: black;
    color: #fff;
    font-size: 13px;
    padding: $padding 0 10px 0;
    width: 100%;

    &__inner {
        padding: 0 10px;
        position: relative;
    }

    &__up {
        background-color: #444;
        position: absolute;
        right: 20px;
        top: -$padding;
    }

    &__arrow {
        color: #ddd;
        font-size: 20px;
        padding: 6px 12px;
    }

    &__main {
        border-bottom: 1px solid #444;
        display: flex;
        margin-bottom: 10px;
        padding-bottom: 20px;
    }

    &__image {
        margin-right: 20px;
        min-width: 25%;
    }

    &__logo {
        background: no-repeat center center;
        background-size: contain;
        height: 20px;
        width: 200px;
    }

    &__links {
        margin-right: 40px;
        width: 22%;
    }

    &__content {
        line-height: 22px;
    }

    &__link {
        display: block;
        white-space: nowrap;
    }

    &__header {
        font-weight: 600;
        margin-bottom: 10px;
    }

    &__address {
        flex-grow: 1;
        max-width: 350px;
    }

    &__company {
        margin-bottom: 2px;
    }

    &__footer {
        align-items: center;
        color: #d0d3c5;
        display: flex;
        font-size: 12px;
        justify-content: space-between;
    }

    &__icons {
        color: #d0d3c5;
        font-size: 14px;
    }

    &__icon {
        margin-left: 5px;
    }

    &__partner {
        font-weight: 900;
    }

    @media (max-width: 769px) {
        &__main {
            flex-wrap: wrap;
            justify-content: center;
        }

        &__links {
            margin: 0;
        }
    }

    @media (max-width: 400px) {
        &__main {
            flex-direction: column;
            padding: 0 20px 20px 20px;
        }

        &__image {
            margin: 0 auto 14px auto;
        }

        &__links {
            margin: 0 0 14px 0;
            width: 100%;
        }

        &__footer {
            flex-direction: column;
        }

        &__copyright {
            margin-bottom: 6px;
        }

        &__language {
            margin-bottom: 6px;
        }
    }
}

a:hover {
    color: $color__cerulean-200;
}

</style>

import { createStore } from 'vuex';
import pathify from 'vuex-pathify';

import partners from '@js/executives/store/modules/partners.js';
import partnerModules from '@js/executives/store/modules/partnerModules.js';
import user from '@js/executives/store/modules/user/user.js';
import firms from '@js/executives/store/modules/firms.js';
import content from '@js/executives/store/modules/content.js';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
    plugins: [pathify.plugin],
    modules: {
        user,
        firms,
        partners,
        content,
        ...partnerModules,
    },
    strict: debug,
    getters: {
        loggedIn(state) {
            return !!state.user.user;
        },
    },
});

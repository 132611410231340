<template>
    <div
        class="b-application b-nal-layout"
        :class="langClass"
    >

        <div
            v-show="showModal"
            class="b-nal-layout__layer"
        >
        </div>

        <notifications ref="notifications"></notifications>

        <nal-nav v-if="showNavFooter"></nal-nav>

        <div class="b-application__main">
            <div class="b-application__content">
                <router-view></router-view> <!-- Do not touch -->
            </div>
        </div>

        <!-- For footer -->
        <nal-footer
            v-if="showNavFooter"
            class="b-application__footer"
        >
        </nal-footer>

        <cookie-banner colorClass="u-primary-color"></cookie-banner>

        <transition name="t-modal">
            <modal-component
                v-if="showModal"
                ref="modal"
                :modal-style="nal.modal.style"
                :has-no-padding="nal.modal.hasNoPadding"
                :is-loading="nal.modal.isLoading"
                :colorClass="nal.modal.colorClass"
                :document="nal.modal.document"
                :blur="nal.modal.blur"
                @back="closeCurrentModal"
                @close="closeAllModals"
                v-on="nal.modal.on"
            >
                <keep-alive>
                    <component
                        :is="nal.modal.component"
                        :data="nal.modal.data"
                        v-bind="nal.modal.data"
                        @back="closeCurrentModal"
                        @close="closeAllModals"
                        v-on="nal.modal.on"
                    >
                    </component>
                </keep-alive>
            </modal-component>
        </transition>

    </div>
</template>

<script>
import displayRouteModal from '@js/executives/displayRouteModal.js';
import interactsWithModalComponent from '@js/vue-mixins/interactsWithModalComponent.js';

import CookieBanner from '@js/vue-components/layout/cookie-banner.vue';
import Notifications from '@js/vue-components/general/notification/notifications.vue';
import NalNav from './layout/nal-nav.vue';
import NalFooter from './layout/nal-footer.vue';
import ModalComponent from '../../vue-components/general/modal/modal-component.vue';

export default {
    name: 'Application',
    components: {
        'cookie-banner': CookieBanner,
        'notifications': Notifications,
        'nal-nav': NalNav,
        'nal-footer': NalFooter,
        'modal-component': ModalComponent,
    },
    mixins: [
        interactsWithModalComponent,
    ],
    computed: {
        showNavFooter() {
            return this.$route && !this.$route.path.includes('register')
                && !this.$route.path.includes('login')
                && !this.$route.path.includes('recover')
                && !this.$route.path.includes('reset');
        },
        langClass() {
            return `lang-${window.nal.locale}`;
        },
    },
    watch: {
        '$route': {
            handler(to) {
                if (to.query.modal) {
                    this.$nextTick(() => {
                        displayRouteModal(to);
                        this.$router.push({ name: to.name });
                    });
                }
            },
            immediate: true,
        },
    },
};

</script>

<style lang="scss">
@import "@sass/mixins";
@import "@sass/variables";
@import "@sass/home-nal/variables";
@import "@sass/home-nal/utilities";

html, body {
    min-height: 100%;
    position: relative;
    scroll-behavior: smooth;
}

body {
    background-color: #f4f4f4;
}

.b-application {
    color: $color__green-600;
    display: flex;
    flex-direction: column;
    font-family: Nunito, sans-serif;
    min-height: 100%;
    position: relative;

    &--full {
        padding: 0;
    }

    &__main {
        flex: 1 0 auto;
        width: 100%;

        &--fixed {
            margin-top: 66px;
        }
    }

    .u-primary-color__bg {
        background-color: rgb(8 112 138 / 75%);
    }

    .u-primary-color__text {
        color: $color__teal-400;
    }

    .u-hover-text:hover {
        color: $color__teal-400;
        cursor: pointer;
    }

    .basic-button {
        color: #fff;
        display: inline-flex;
        font-size: 13px;
        font-weight: 400;
        padding: 5px 14px;
        text-transform: uppercase;
    }

    .lowercase-button {
        color: #fff;
        display: inline-flex;
        font-size: 14px;
        font-weight: 400;
        padding: 5px 14px;
    }

    .border-button {
        border: 1px solid;
        display: inline-flex;
        font-weight: 400;
        padding: 3px 8px;
    }

    .c-input-field {
        .c-input-field__input {
            font-size: 15px;
            height: 40px;
        }
    }

    &__footer {
        z-index: z('over');
    }
}
</style>

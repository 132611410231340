<template>
    <div class="o-nal-nav">
        <div class="o-nal-nav__inner">
            <div class="o-nal-nav__left">
                <router-link
                    to="/home"
                    exact
                >
                    <div
                        class="o-nal-nav__logo"
                        style="background-image: url('/images/logo-blue.png')"
                    >
                    </div>
                </router-link>
            </div>
            <form
                ref="logoutForm"
                id="logout-form"
                action="/executives/logout"
                method="POST"
                style="display: none;"
            >
                <input
                    type="hidden"
                    name="_token"
                    :value="token"
                >
            </form>

            <div class="o-nal-nav__right o-nal-nav__large">
                <div
                    class="o-nal-nav__links"
                >
                    <router-link
                        v-for="(link, index) in links"
                        :key="index"
                        class="o-nal-nav__link o-nal-nav__link--text u-pointer u-hover--grow"
                        :to="{ name: link.name }"
                    >
                        {{ trans('home-nal.nav.' + link.text) }}
                    </router-link>

                    <div class="o-nal-nav__link o-nal-nav__link--text u-pointer">
                        <a href="https://ezekia.com">
                            {{ trans('home-nal.nav.searchFirm') }}
                        </a>
                    </div>

                    <div
                        class="u-button-cta u-hover--grow"
                        @click="enter"
                    >
                        {{ loggedIn ? trans('home-nal.nav.enter') : trans('home-nal.nav.memberships') }}
                    </div>

                    <router-link
                        v-if="!loggedIn"
                        class="u-button-secondary u-hover--grow"
                        :to="loginUrl"
                    >
                        {{ trans('home-nal.nav.login') }}
                    </router-link>
                    <a
                        v-else
                        class="o-nal-nav__link o-nal-nav__link--text u-pointer"
                        href="/executives/logout"
                        @click.prevent="logout"
                    >
                        {{ trans('home-nal.nav.logout') }}
                    </a>

                </div>
            </div>

            <div class="o-nal-nav__resp o-nal-nav__small">

                <div
                    class="o-nal-nav__burger u-hover--grow"
                    @click="respDropdown = !respDropdown"
                >
                    <i class="fal fa-bars"></i>
                </div>

                <div
                    v-show="respDropdown"
                    class="o-nal-nav__resp-dropdown"
                >

                    <div v-show="!respContent">
                        <router-link
                            v-for="(link, index) in links"
                            :key="index"
                            class="o-nal-nav__resp-line o-nal-nav__resp-main"
                            :to="{ name: link.name }"
                            @click="respDropdown = false"
                        >
                            {{ trans('home-nal.nav.' + link.text) }}
                        </router-link>

                        <div class="o-nal-nav__resp-line o-nal-nav__resp-main">
                            <a href="https://ezekia.com">
                                {{ trans('home-nal.nav.searchFirm') }}
                            </a>
                        </div>

                        <div
                            class="o-nal-nav__resp-line o-nal-nav__resp-main u-pointer"
                            @click="enter"
                        >
                            {{ loggedIn ? trans('home-nal.nav.enter') : trans('home-nal.nav.memberships') }}
                        </div>

                        <router-link
                            v-if="!loggedIn"
                            class="o-nal-nav__resp-line o-nal-nav__resp-main"
                            :to="loginUrl"
                        >
                            {{ trans('home-nal.nav.login') }}
                        </router-link>

                        <a
                            v-else
                            class="o-nal-nav__resp-line o-nal-nav__resp-main"
                            href="/executives/logout"
                            @click.prevent="logout"
                        >
                            {{ trans('home-nal.nav.logout') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { userStore } from '@js/home-nal/user/userStore.js';

const links = [
    {
        name: 'contact',
        text: 'contact',
    },
];

export default {
    name: 'NalNav',
    data() {
        return {
            respDropdown: false,
            respContent: false,
            loginUrl: { name: 'login' },
            signupUrl: { name: 'register' },
        };
    },
    computed: {
        loggedIn() {
            return userStore.loggedIn();
        },
        finishedRegistration() {
            return userStore.finishedRegistration();
        },
    },
    methods: {
        logout() {
            this.$refs.logoutForm.submit();
        },
        enter() {
            this.respDropdown = false;
            if (!this.loggedIn) {
                const url = 'https://notactivelylooking.com/membership';
                return window.open(url, '_blank');
            }
            if (window.awaiting2FA) {
                return this.$router.push({ path: '/login/token' });
            }
            if (window.browserNeedsVerification) {
                return this.$router.push({ path: '/login/new-browser-pin' });
            }
            if (window.awaitingActivation) {
                return this.$router.push({ path: '/login/activate' });
            }
            if (!this.finishedRegistration) {
                return this.$router.push(this.signupUrl).catch(_.noop);
            }
            window.location.href = '/';
        },
    },
    watch: {
        respDropdown(newValue) {
            if (!newValue) {
                this.respContent = false;
            }
        },
    },
    created() {
        this.links = links;
        this.token = window.token;
    },
};
</script>

<style lang="scss" scoped>

@import "@sass/mixins";
@import "@sass/variables";
@import "@sass/home-nal/variables";
@import "@sass/home-nal/utilities";

.o-nal-nav {
    background-color: #fff;
    color: $color__green-600;
    font-size: 15px;
    width: 100%;

    &__inner {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 3px 30px;
    }

    &__left {
        align-items: center;
        display: flex;
        padding: 5px 0;
    }

    &__logo {
        background: no-repeat center center;
        background-size: contain;
        height: 50px;
        width: 270px;
    }

    &__right {
        align-items: center;
        display: flex;
    }

    &__current {
        font-size: 14px;
        font-weight: 400;
    }

    &__links {
        display: flex;
        margin-right: 10px;
    }

    &__link {
        border-top: 2px solid transparent;
        padding: 10px 18px;
        position: relative;

        &--text {
            align-self: center;
            text-align: center;

            &:hover {
                background-color: $color__cerulean-100;
                border-radius: 6px;
                color: $color__teal-400;
            }
        }
    }

    &__small {
        display: none;
    }

    &__burger {
        font-size: 30px;
        line-height: 18px;
        padding: 14px 0;
    }

    &__resp {
        position: relative;

        &-dropdown {
            background-color: white;
            box-shadow: 0 4px 8px -1px rgba(0, 0, 0, .2);
            position: absolute;
            right: -20px;
            top: 47px;
            width: 220px;
            z-index: z("over");
        }

        &-main {
            border-left: 2px solid transparent;
            justify-content: space-between;
            padding: 18px 20px 14px 20px;
        }

        &-line {
            align-items: center;
            border-left: 2px solid transparent;
            display: flex;

            &:hover {
                background-color: $color__cerulean-100;
                color: $color__teal-400;
            }
        }
    }

    @media (max-width: 1260px) {
        &__left {
            padding: 0;
        }

        &__large {
            display: none;
        }

        &__small {
            display: block;
        }
    }

    @media (max-width: 1024px) {
        &__resp-main {
            padding: 14px 20px 12px 20px;
        }
    }

    @media (max-width: 650px) {
        &__resp-main {
            padding: 12px 20px;
        }
    }

    @media (max-width: 500px) {
        &__logo {
            width: 230px;
        }
    }

    @media (max-width: 350px) {
        &__logo {
            width: 200px;
        }

        &__resp-main {
            padding: 10px 20px;
        }

    }
}

a:hover {
    color: $color__teal-400;
}

.active {
    color: $color__teal-400;
    font-weight: 600;
}

.u-button-cta {
    align-self: center;
    margin: 0 15px 0 10px;
    padding: 10px 20px;
    text-align: center;
}

.u-button-secondary {
    align-self: center;
    margin-left: 10px;
    padding: 10px 20px;
    text-align: center;

    &:hover {
        color: $color__teal-400;
    }
}

</style>